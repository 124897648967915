export interface FilterOption {
  key: string
  description: string
  hint?: string
  apiEndPoint?: string
}

export const FILTER_OPTIONS: FilterOption[] = [
  { key: 'today', description: 'Today' },
  { key: 'yesterday', description: 'Yesterday' },
  { key: 'last_7_days', description: 'Last 7 Days' },
  { key: 'last_30_days', description: 'Last 30 Days' },
  { key: 'this_month', description: 'This Month' },
  { key: 'custom', description: 'Custom' },
]

export type FilterOptionKey = (typeof FILTER_OPTIONS)[number]['key']

export const ATTRIBUTION_SELECTION_OPTIONS: FilterOption[] = [
  {
    key: 'last_click_attribution',
    description: 'Last Click',
    hint: '100% credits the first click for the conversion.',
    apiEndPoint: 'api/sources/sales-funnel-activity/',
  }
]

export const COMING_SOON_ATTRIBUTION_SELECTION_OPTIONS: FilterOption[] = [
  {
    key: 'first_click_attribution',
    description: 'First Click',
    hint: '100% credits the last click for the conversion.',
    apiEndPoint: 'api/sources/sales-funnel-activity-first-click/',
  },
  {
    key: 'first_and_last_click_attribution',
    description: 'First + Last Click',
    hint: '100% credits the first and last click for the conversion.',
  },
  {
    key: 'linear_attribution',
    description: 'Linear',
    hint: 'Equally credits all source for the conversion.',
  },
  {
    key: 'last_marketing_click_attribution',
    description: 'Last Marketing Click',
    hint: '100% credits the last paid source for the conversion.',
  },
]
