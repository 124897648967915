import httpClient from 'http/httpClient'
import {
  CompanyDetail,
  CompanyId,
  ConfigurationRequestBody,
  CreateCompany,
  CreateConfigurationData,
  CreateFunnelRequest,
  CreateFunnelResponse,
  CreateFunnelStageAPI,
  CreateFunnelStageResponse,
  CreatePaidSourceResponse,
  DeleteStageResponse,
  FunnelProgressResponse,
  GetOtherSourceFullList,
  WorkspaceService as IWorkspaceService,
  MandatoryField,
  OtherSourceDataItem,
  OtherSources,
  PaidSources,
  ReconfigurePaidSourceRequest,
  RunTestResponse,
  SaveFunnelStageAPI,
  StageType,
  TargetValuesList,
  UpdateConfigurationAPI,
  UpdateConfigurationResponse,
  UpdateFunnelAPI,
  UpdateFunnelStageAPI,
  UpdateFunnelStageResponse,
  UpdateOtherSources,
  UpdatedAllOtherSources,
  UTMCategory,
  PendingUTMSource,
  PendingCategory,
  accountList,
  companyIdWithPage,
  dataFieldsReq,
  deleteStageReq,
  errorGraph,
  getConfigurationResponse,
  googlePayload,
  postUnmappedOtherSources,
  selectedAccountId,
  webhookUrl,
} from 'types/workspace.model'

const WorkspaceService = (): IWorkspaceService => {
  return {
    createFunnel: (
      request: CreateFunnelRequest,
    ): HttpPromise<CreateFunnelResponse> => {
      return httpClient.post('/api/funnels/', { data: request })
    },
    updateFunnel: (
      request: UpdateFunnelAPI,
    ): HttpPromise<CreateFunnelResponse> => {
      return httpClient.put(`/api/funnels/${request.funnelId}/`, {
        data: request.body,
      })
    },
    createStage: (
      data: CreateFunnelStageAPI,
    ): HttpPromise<CreateFunnelStageResponse> => {
      return httpClient.post(`/api/funnels/${data.funnelId}/stages/`, {
        data: data.body,
      })
    },
    updateStage: (
      data: UpdateFunnelStageAPI,
    ): HttpPromise<UpdateFunnelStageResponse> => {
      return httpClient.put(
        `/api/funnels/${data.funnelId}/stages/${data.stageId}/`,
        {
          data: data.body,
        },
      )
    },
    saveFunnelStages: (data: SaveFunnelStageAPI) => {
      return httpClient.put(`/api/funnels/${data.funnelId}/stages/save/`, {
        data: data.body,
      })
    },
    getStages: (funnelId: string): HttpPromise<any> => {
      return httpClient.get(`/api/funnels/${funnelId}/stages-list/`)
    },
    getFunnelProgress: (
      company_id: string,
    ): HttpPromise<FunnelProgressResponse> => {
      return httpClient.post(`/api/company/${company_id}/progress/`)
    },

    postCreateCompany: (request: CreateCompany): HttpPromise<CompanyDetail> => {
      return httpClient.post('/api/agency/company/', { data: request })
    },
    updateConfiguration: (
      request: UpdateConfigurationAPI,
    ): HttpPromise<UpdateConfigurationResponse> => {
      return httpClient.put(
        `/api/funnels/stages/${request.stageId}/configuration/`,
        {
          data: request.body,
        },
      )
    },
    getConfiguration: (
      stageId: string,
    ): HttpPromise<getConfigurationResponse> => {
      return httpClient.get(`/api/funnels/stages/${stageId}/configuration/`)
    },
    getMandatoryFields: (
      request: dataFieldsReq,
    ): HttpPromise<MandatoryField> => {
      let queryParams = []
      if (request.isLastStep) {
        queryParams.push('is_final_step')
      }
      if (request.isSalesPersonAssigned) {
        queryParams.push('is_sales_person_assigned')
      }
      const queryString =
        queryParams.length > 0
          ? `?stage_dependency=${queryParams.join(',')}`
          : ''
      return httpClient.get(`/api/funnels/data-fields/${queryString}`)
    },
    deleteStage: (req: deleteStageReq): HttpPromise<DeleteStageResponse> => {
      return httpClient.delete(
        `/api/funnels/${req.funnelId}/stages/${req.stageId}/`,
      )
    },
    runTest: (stageId: string): HttpPromise<RunTestResponse> => {
      return httpClient.post(`/api/funnels/stages/${stageId}/run-test/`)
    },
    getWebhookUrl: (stageId: string): HttpPromise<webhookUrl> => {
      return httpClient.get(`/api/funnels/stages/${stageId}/generate-url/`)
    },
    postNewCreateConfiguration: (
      request: CreateConfigurationData,
    ): HttpPromise<ConfigurationRequestBody> => {
      return httpClient.post(
        `/api/funnels/stages/${request.stageId}/configuration/`,
        { data: request.body },
      )
    },
    getPaidSources: (request: companyIdWithPage): HttpPromise<PaidSources> => {
      return httpClient.get(`/api/marketing/${request.companyId}/paid-sources/`)
    },
    postNewPaidSources: (request: {
      name: string
    }): HttpPromise<CreatePaidSourceResponse> => {
      return httpClient.post(`/api/marketing/paid-source-config/new/`, {
        data: request,
      })
    },
    postGooglePayload: (request: googlePayload): HttpPromise<accountList> => {
      return httpClient.post('/api/marketing/callback/', { data: request })
    },
    postReconfigurePaidSource: (
      request: ReconfigurePaidSourceRequest,
    ): HttpPromise<accountList> => {
      return httpClient.post('/api/marketing/reconfigure/', { data: request })
    },
    postTargetValues: (request: TargetValuesList): HttpPromise<any> => {
      return httpClient.post(
        `/api/marketing/paid_source/${request.configId}/targets/`,
        { data: request.target },
      )
    },
    getTargetValues: (configId: string): HttpPromise<TargetValuesList> => {
      return httpClient.get(
        `/api/marketing/paid_source/${configId}/get-targets/`,
      )
    },
    putTargetValues: (request: TargetValuesList): HttpPromise<any> => {
      return httpClient.put(
        `/api/marketing/paid_source/${request.configId}/update-targets/`,
        {
          data: request.target,
        },
      )
    },
    postSavePaidSources: (request: CompanyId): HttpPromise<any> => {
      return httpClient.post('/api/marketing/paid_source/save-and-continue/', {
        data: request,
      })
    },
    postOtherSources: (request: OtherSources): HttpPromise<any> => {
      return httpClient.post('/api/marketing/other_source/', {
        data: request,
      })
    },
    getOtherSources: (otherSourcesId: string): HttpPromise<any> => {
      return httpClient.get(
        `/api/marketing/other_source/${otherSourcesId}/get-targets/`,
      )
    },
    postUnmappedOtherSources: (
      request: postUnmappedOtherSources,
    ): HttpPromise<OtherSources> => {
      return httpClient.post(
        `/api/marketing/unmapped_source/${request.id}/convert-to-other-source/`,
        { data: request.body },
      )
    },
    putUnmappedOtherSource: (
      request: UpdatedAllOtherSources,
    ): HttpPromise<OtherSources> => {
      return httpClient.put(`/api/marketing/other_source/${request.id}/`, {
        data: request.body,
      })
    },
    getUnmapedOtherSource: (
      companyId: string,
    ): HttpPromise<OtherSourceDataItem[]> => {
      return httpClient.get(
        `api/marketing/unmapped_source/${companyId}/get-unmapped-source-list/`,
      )
    },
    getUnmappedSourceUtmData: (
      sourceId: string,
    ): HttpPromise<OtherSourceDataItem[]> => {
      return httpClient.get(
        `api/marketing/unmapped_source/${sourceId}/view-unmapped-source/`,
      )
    },
    deleteBucket: (id: string): HttpPromise<string> => {
      return httpClient.delete(`/api/marketing/other_source/${id}/`)
    },
    putOtherSources: (request: UpdateOtherSources): HttpPromise<any> => {
      return httpClient.put(
        `/api/marketing/other_source/${request.otherSourceId}/`,
        {
          data: request.otherSourceUpdatedData,
        },
      )
    },
    getOtherSourcesList: (
      request: CompanyId,
    ): HttpPromise<GetOtherSourceFullList> => {
      return httpClient.get(
        `/api/marketing/other_source/${request.company}/get-list/`,
      )
    },
    deleteOtherSource: (otherSourcesId: string): HttpPromise<any> => {
      return httpClient.delete(`/api/marketing/other_source/${otherSourcesId}/`)
    },
    postSaveOtherSources: (request: CompanyId): HttpPromise<any> => {
      return httpClient.post('/api/marketing/other_source/save-and-continue/', {
        data: request,
      })
    },
    getTestErrorLogs: async (funnelId: string) => {
      return await httpClient.get(`/api/funnels/${funnelId}/test-error-logs/`)
    },
    putSelectedAccount: (request: selectedAccountId): HttpPromise<any> => {
      return httpClient.put(
        `/api/marketing/paid_source/${request.configId}/set-account-id/`,
        {
          data: request.account,
        },
      )
    },
    getErrorGraph: (companyId: string): HttpPromise<errorGraph> => {
      return httpClient.get(`/api/dashboard/events/audit/${companyId}/`)
    },
    getStageTypes: (): HttpPromise<StageType> => {
      return httpClient.get(`api/funnels/stage-types/`)
    },
    getUTMCategories: (): HttpPromise<UTMCategory[]> => {
      return httpClient.get(`/api/sources/categories/`)
    },
    createUTMCategory: (categoryName: string): HttpPromise<UTMCategory> => {
      return httpClient.post(`/api/sources/categories/`, { data: { name: categoryName } })
    },
    updateUTMCategory: (data: UTMCategory): HttpPromise<UTMCategory> => {
      return httpClient.put(`/api/sources/categories/${data.id}/`, { data: { name: data.name } })
    },
    deleteUTMCategory: (categoryId: number): HttpPromise<UTMCategory> => {
      return httpClient.delete(`/api/sources/categories/${categoryId}/`)
    },
    getPendingUTMSources: (): HttpPromise<PendingUTMSource[]> => {
      return httpClient.get(`/api/sources/utm_sources/pending/`)
    },
    getPendingCategories: (): HttpPromise<PendingCategory[]> => {
      return httpClient.get(`/api/sources/categories/pending/`)
    }
  }
}

export default WorkspaceService()
